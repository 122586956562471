import firebase from 'firebase/compat/app';
const firebaseConfig = {
  apiKey: "AIzaSyCAowCDyHC5b0HhhIBvxVqc0o3lLSMXnJM",
  authDomain: "vncodelab2.firebaseapp.com",
  databaseURL: "https://vncodelab2-default-rtdb.firebaseio.com",
  projectId: "vncodelab2",
  storageBucket: "vncodelab2.appspot.com",
  messagingSenderId: "852532707206",
  appId: "1:852532707206:web:5281cd31d29828fbc7f607"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase;