import React, {useEffect, useState} from 'react';

import {BsTextLeft} from 'react-icons/bs';
import ParagraphElement from './ParagraphElement';
import TableContent from './TableContent';
import {useLocation} from "react-router-dom";


const ParagraphContent = ({step, content, index}) => {
    const [isContentVisible, setIsContentVisible] = useState(false);
    const toggleContentVisibility = () => {
        setIsContentVisible(!isContentVisible);
    };
    const print = new URLSearchParams(useLocation().search).get('print');
    const iconOpacity = isContentVisible ? 1 : 0.5;
    if (content.paragraph && content.paragraph.elements && content.paragraph.elements.length > 0) {
        const paragraphText = content.paragraph.elements.map((element) => element.textRun?.content).join('');
        const isShort = content.paragraph.bullet || paragraphText.length < 200 || (paragraphText.length < 400 && paragraphText.trim().endsWith(':'));
        const paragraphStyleType = content.paragraph.paragraphStyle.namedStyleType;
        const ContentComponent = paragraphStyleType === 'HEADING_2' ? 'h2' : paragraphStyleType === 'HEADING_3' ? 'h3' : 'p';


        return (<div>
                {!isShort ? (<div className="button-visibility" style={{opacity: iconOpacity}}>
                        <BsTextLeft onClick={toggleContentVisibility}/>
                    </div>) : null}
                {((!isShort && !isContentVisible) && (!print)) ? null : (
                    <ContentComponent style={{textAlign: content?.paragraph?.paragraphStyle?.alignment}}>
                        {content.paragraph.elements.map((element, index) => (
                            <ParagraphElement key={index} element={element} index={index}/>))}
                    </ContentComponent>

                )}
            </div>);
    } else if (content.table) {
        return <TableContent table={content.table}/>;
    } else {
        return null;
    }
};
export default ParagraphContent;
