// utils.js
export function readElements(elements) {
    let result = '';
    if (elements) elements.forEach((element) => {
        if (element.paragraph) {
            element.paragraph.elements.forEach((paragraphElement) => {
                result += readParagraphElement(paragraphElement);
            });
        } else if (element.table) {
            element.table.tableRows.forEach((row) => {
                row.tableCells.forEach((cell) => {
                    result += readElements(cell.content);
                });
            });
        }
    });
    return result;
}

export function readParagraphElement(element) {
    const run = element.textRun;
    if (!run || !run.content) {
        return '';
    }
    return run.content;
}
