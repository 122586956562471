import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import 'bootstrap/dist/css/bootstrap.css';

import './index.css';
import './css/custom.css'; // Thay thế import của CSS thành SCSS
import 'bootstrap/dist/js/bootstrap';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />

); 
reportWebVitals();
