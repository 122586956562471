import React, {useState, useRef, useEffect} from 'react';
import SpeechBubble from './SpeechBubble';
import app from '../firebase'; // Import your Firebase configuration
import {getDatabase, ref, set} from "firebase/database";
import firebase from "firebase/compat/app";

const StepButton = ({
                        step,
                        index,
                        currentStep,
                        handleStepClick,
                        heading1Count,
                        heading2Count,
                        heading3Count,
                        onlineList,
                        room
                    }) => {
    const [isSpeechBubbleVisible, setSpeechBubbleVisible] = useState(false);
    const userOnlineRef = useRef(null);
    const [userOnline, setUserOnline] = useState(null);

    useEffect(() => {
        let userOnline = onlineList[index];
        setUserOnline(userOnline);
    }, [onlineList]);

    const handleMouseEnter = () => {
        const userOnlineRect = userOnlineRef.current.getBoundingClientRect();
        setSpeechBubbleVisible(true);
        setSpeechBubblePosition({
            x: userOnlineRect.left + userOnlineRect.width / 2, y: userOnlineRect.top + userOnlineRect.height,
        });
    };

    const handleMouseLeave = () => {
        setSpeechBubbleVisible(false);
    };

    const handleStepButtonClick = () => {
        handleStepClick(index);
    };

    const [speechBubblePosition, setSpeechBubblePosition] = useState({x: 0, y: 0});

    return (<li
        className={`step-button nav-item list-group-item ${index === currentStep ? 'active' : ''} ${index > currentStep ? 'inactive' : ''}`}
        onClick={handleStepButtonClick}>

        {(room.config.slideHeading === "h3"||room.config.slideHeading === "h2") ? (<div
            className={`step-number ${step.level === 2 ? 'step-number1' : ''} ${step.level === 3 ? 'step-number2' : ''}`}>
            {step.level === 1 ? heading1Count : step.level === 2 ? `${heading2Count}` : step.level === 3 ? `${heading2Count}.${heading3Count}` : 1}
        </div>) : (<div
            className={`step-number ${step.level === 1 ? 'step-number1' : ''}`}>
            {heading1Count}
        </div>)}

        <div className="step-name">{step.name}</div>
        <div ref={userOnlineRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
             className={`online-step badge badge-secondary bg-secondary ${isSpeechBubbleVisible ? 'active' : ''}`}>
            {userOnline?.count}
        </div>
        {isSpeechBubbleVisible && (<SpeechBubble
            userOnline={userOnline}
            position={{x: speechBubblePosition.x, y: speechBubblePosition.y}}
        />)}
    </li>);
};

export default StepButton;
