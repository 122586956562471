import React, {useEffect, useState} from "react";
import {useLocation} from 'react-router-dom';

import NavBar from '../components/NavBar';
import Heroes from '../components/Heroes';
import Footer from '../components/Footer';
import Cards from '../components/Cards';
import LoginModal from "../modals/LoginModal";
import app from "../firebase";
import firebase from "firebase/compat/app";
import {onAuthStateChanged, getAuth} from "firebase/auth";

function Home() {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState();
    const [showLogin, setShowLogin] = useState(false);
    const location = useLocation();
    const cardType = location.pathname === '/docs' ? 'mydocs' : 'features';

    const handleCloseLogin = () => {
        setShowLogin(false);
    }

    const onShowLogin = () => {
        setShowLogin(true)
    };

    useEffect(() => {
        const auth = getAuth(app);
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
            } else setUser(null);
            setShowLogin(false)
            setLoading(false);
        });
    }, []);

    return (<>
        <NavBar onShowLogin={onShowLogin} user={user}/>
        {location.pathname === '/docs' ?
            <Cards type={cardType} user={user}/> :
            <Heroes user={user} onShowLogin={onShowLogin} loading={loading}/>}
        <Footer/>
        <LoginModal
            show={showLogin}
            user={user}
            handleClose={handleCloseLogin}
            onSignOut={() => {
                firebase.auth().signOut();
            }}
        />
    </>);
}

export default Home;