import React from 'react';
import CodeDisplay from './CodeDisplay';
import ParagraphContent from './ParagraphContent';
import Exercise from './Exercise';
import Quiz from './Quiz';
import {readElements} from '../utils';

const TableCell = ({content, header}) => (<td className={header ? 'header-cell' : 'regular-cell'}>
    {content && content.map((paragraph, index) => (<ParagraphContent key={index} content={paragraph}/>))}
</td>);

const TableRow = ({cells, header}) => (<tr className={header ? 'header-row' : 'regular-row'}>
    {cells.map((cell, index) => (<TableCell key={index} content={cell.content} header={header}/>))}
</tr>);

const TableContent = ({table}) => {
    const hasTwoColumns = table.tableRows.length > 0 && table.tableRows[0].tableCells.length === 2;

    if (hasTwoColumns) {
        if (table.tableRows.length === 2) {
            let content = readElements(table.tableRows[1].tableCells[1].content);
            const SecondEmpty = !table.tableRows[1].tableCells[1].content[0]?.paragraph || content.trim() === '';
            if (SecondEmpty) return <Exercise table={table}/>;
        } else if (table.tableRows.length > 2) {
            let content = readElements(table.tableRows[2].tableCells[1].content);
            const ThirdEmpty = !table.tableRows[2].tableCells[1].content[0]?.paragraph || content.trim() === '';
            if (ThirdEmpty) {
                return <Quiz table={table}/>;
            }
        }
    }

    if (table.tableRows.length === 1 && table.tableRows[0].tableCells.length === 1) {
        const textStyle = table.tableRows[0].tableCells[0].content[0].paragraph.elements[0].textRun.textStyle;
        if (textStyle && textStyle.weightedFontFamily && (textStyle.weightedFontFamily.fontFamily === 'Courier New' || textStyle.weightedFontFamily.fontFamily === 'Consolas' || textStyle.weightedFontFamily.fontFamily === 'Menlo')) {
            return <CodeDisplay table={table}/>;
        }
    }

    // Default rendering for other tables
    return (<table className="table table-bordered table-hover table-striped" style={table.tableStyle}>

        <tbody>
        {table.tableRows.map((row, index) => (<TableRow key={index} cells={row.tableCells}/>))}
        </tbody>
    </table>);
};

export default TableContent;
