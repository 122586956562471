import React from 'react';
import StepHeader from './StepHeader';
import StepFooter from './StepFooter';
import StepBody from './StepBody';
import { useLocation } from "react-router-dom";

const StepContent = ({ steps, step, content, room, currentStep }) => {
    let labConfig = room.config;
    const contentClass = (((labConfig.slideHeading === "h2" || labConfig.slideHeading === "h3") && (step.level <= 1))) || (labConfig.slideHeading === "h1" && step.level === 0) ? 'slide-title' : '';
    const childSteps = steps.filter((s) => s.parentIndex === step.index);
    const showContent = content.content.length > 0 && step.level > 0;
    const print = new URLSearchParams(useLocation().search).get('print');

    return (
        <div className={`step-content ${contentClass}`}>
            <StepHeader step={step} />
            {(!print || (print && showContent)) && ( // Kiểm tra trang không phải là trang in hoặc là trang in nhưng có nội dung
                <div className="step-body">
                    {showContent && step.level ? (
                        <StepBody content={content.content} />
                    ) : (
                        <div className="child-step">
                            <ol>
                                {childSteps.map((childStep) => (
                                    <li key={childStep.name}>
                                        <p>{childStep.name}</p>
                                    </li>
                                ))}
                            </ol>
                        </div>
                    )}
                </div>
            )}
            {!print && <StepFooter currentStep={currentStep} />} {/* Hiển thị StepFooter nếu không phải trang in */}
        </div>
    );
};

export default StepContent;
