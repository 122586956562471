// config.js
const isLocal = process.env.NODE_ENV === 'development';

const config = {
  API_BASE_URL: isLocal ? "http://localhost:8014/api" : "https://vncodelab.com/old/api",
};

export default config;
//
// const config = {
//   API_BASE_URL: "http://localhost:8014/api",
// };

// const config = {
//   API_BASE_URL: "http://101.96.66.219:8014/api",
// };

// const config = {
//   API_BASE_URL: "http://192.168.1.75:8014/api",
// };
