import React, { useState } from "react";

import NavBar from "../components/NavBar";
import RoadMap from "../components/RoadMap";
import StudentList from "../components/StudentList";
import Footer from "../components/Footer";


const columns = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Name' },
  { id: 'age', label: 'Age' },
];

const data = [
  { id: 1, name: 'John', age: 30 },
  { id: 2, name: 'Jane', age: 25 },
  // Thêm dữ liệu khác tại đây
];


function MyClasses() {
  const [showLogin, setShowLogin] = useState(false);
  const [userData, setUserData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Hàm này sẽ được gọi từ Component NavBar để cập nhật thông tin người dùng đã đăng nhập
  const updateUserData = (userData) => {
    setUserData(userData);
  };

  const handleCloseLogin = () => {
    setShowLogin(false);
  }

  const onShowLogin = (user) => {
    setShowLogin(true)
  };

  return (
    <div>
      <NavBar onShowLogin={onShowLogin} />
      <RoadMap/>
      <StudentList
        data={data}
        columns={columns}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Footer />
    </div>
  );
};

export default MyClasses;
