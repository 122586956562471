import React, {useState, useEffect, useRef} from 'react';
import {useParams, useLocation, useNavigate} from 'react-router-dom';
import '../css/preview.css';
import 'material-icons/iconfont/material-icons.css';
import StepContent from '../components/StepContent';
import Header from '../components/Header';
import config from "../config";
import firebase from "firebase/compat/app";
import app from "../firebase";
import "firebase/compat/database";
import {getDatabase, onDisconnect, ref, set} from "firebase/database";
import "firebase/firestore";
import paged, {registerHandlers, Previewer, Handler} from 'pagedjs';

import SettingsButton from '../components/SettingsButton';


const Preview = () => {
    const {email, id, year} = useParams();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const slideHeadingParam = searchParams.get('slideHeading');
    const print = searchParams.get('print');
    const chap = searchParams.get('chap');
    const update = searchParams.get('update');
    const [currentStep, setCurrentStep] = useState(0);
    const [stepsData, setStepsData] = useState({steps: [], contents: [], listHeading: []});
    const [showStepList, setShowStepList] = useState(false);
    const [loading, setLoading] = useState(true);
    const [room, setRoom] = useState(null);
    const [user, setUser] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const navigate = useNavigate();
    const [slideTitle, setSlideTitle] = useState(null);
    const [template, setTemplate] = useState(null);
    const [settingsChangeCounter, setSettingsChangeCounter] = useState(0);

    const handleStepClick = (index) => {
        setCurrentStep(index);
        setShowStepList(false);
        window.location.hash = `#${index}`;
        if (room.roomID) {
            const db = getDatabase(app);
            let link = chap ? `/chap${chap}` : "";
            set(ref(db, `/labs/${room.docID.replace(/\./g, '')}/${room.roomID}${link}/users/${user.uid}`), {
                step: index, timestamp: firebase.database.ServerValue.TIMESTAMP, name: user.displayName,
            });
        }
    };

    const handleTitleClick = () => {
        handleStepClick(0)
    }
    const handleOptionClick = (selectedItem) => {
        if (prevChap != selectedItem) {
            setSelectedItem(selectedItem)
            setLoading(true)
            setCurrentStep(0);
            // Update URL when selectedItem changes
            const queryParams = new URLSearchParams(location.search);
            queryParams.set('chap', selectedItem);
            navigate(`${location.pathname}?${queryParams.toString()}`);
        } else {
            setCurrentStep(0)
        }
    };
    const handleUserLogin = (userData) => {
        setUser(userData);
    };
    const isRoomInPath = window.location.pathname.includes('room');
    const isUnitInPath = window.location.pathname.includes('unit');

    // Custom hook to get the previous value of a prop/state
    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };
    const prevChap = usePrevious(chap);
    var labConfig;
    useEffect(() => {
        const fetchData = async () => {
            try {
                let listHeading
                let data;
                if (isRoomInPath) {
                    let url = `${config.API_BASE_URL}/room/${id}${(chap ? chap : selectedItem) ? `?chap=${chap ? chap : selectedItem}` : ''}`;
                    let response = await fetch(url);
                    data = await response.json();
                    if (update === 'true' && data.docID) {
                        navigate(`/preview/${data.docID}`);
                        return;
                    }
                    labConfig = data.config;
                    data.teacher = data.userID === user.uid;
                    setRoom(data)
                    //var mang = ["Dlo8Dn", "ArEDlK", "WQIK1L", "QpErCB", "ydZ4k9", "ix3IQY", "k7vuu7", "xLPND1", "lURTu4"];
                    if (labConfig.template) {
                        setTemplate(labConfig.template)
                    } else {
                        setTemplate("NEU")
                    }
                    const db = getDatabase(app);
                    let link = chap ? `/chap${chap}` : "";
                    const refUsers = ref(db, `/labs/${data.docID.replace(/\./g, '')}/${data.roomID}${link}/users/${user.uid}`)
                    await onDisconnect(refUsers).set({});
                    const hash = window.location.hash;
                    const hashIndex = hash ? parseInt(hash.substring(1), 10) : 0;
                    //Vao phong, dang ky step
                    if (hashIndex > 0) {
                        let link = chap ? `/chap${chap}` : "";
                        set(ref(db, `/labs/${data.docID.replace(/\./g, '')}/${data.roomID}${link}/users/${user.uid}`), {
                            step: hashIndex, timestamp: firebase.database.ServerValue.TIMESTAMP, name: user.displayName,
                        });
                    }

                    const handleChapChange = async () => {
                        if (isRoomInPath && room && user) {
                            const db = getDatabase(app);
                            const prevChapRef = ref(db, `/labs/${data.docID.replace(/\./g, '')}/${data.roomID}/chap${prevChap}/users/${user.uid}`);
                            await set(prevChapRef, null); // Set the data to null to clear it
                        }
                    };
                    if (prevChap !== chap) {
                        await handleChapChange();
                    }
                    listHeading = data.listHeading
                    data = data.data
                }else if(isUnitInPath) {
                    let url = `${config.API_BASE_URL}/unit/${year}/${id}`;

                    if (chap || selectedItem || print) {
                        url += '?';
                    }

                    if (chap || selectedItem) {
                        url += `chap=${chap || selectedItem}`;
                    }

                    if (print) {
                        if (chap || selectedItem) {
                            url += '&';
                        }
                        url += `print=${print}`;
                    }

                    let response = await fetch(url);
                    data = await response.json();
                    data.docID = id
                    setRoom(data)
                    labConfig = data.config;
                    listHeading = data.listHeading
                    data = data.data
                    setTemplate("NEU")
                }
                else {
                    //let url = `${config.API_BASE_URL}/preview/${id}${(chap ? chap : selectedItem) ? `?chap=${chap ? chap : selectedItem}` : ''}`;
                    //let url = `${config.API_BASE_URL}/preview/${id}${(chap || selectedItem) ? `?chap=${chap || selectedItem}` : ''}${print ? `&print=${print}` : ''}`;
                    let url = email
                        ? `${config.API_BASE_URL}/preview/${email}/${id}${(chap || selectedItem) ? `?chap=${chap || selectedItem}` : ''}${print ? `&print=${print}` : ''}`
                        : `${config.API_BASE_URL}/preview/${id}${(chap || selectedItem) ? `?chap=${chap || selectedItem}` : ''}${print ? `&print=${print}` : ''}`;
                    let response = await fetch(url);
                    data = await response.json();
                    data.docID = id
                    setRoom(data)
                    labConfig = data.config;
                    listHeading = data.listHeading
                    data = data.data
                    setTemplate("NEU")
                }
                let steps = [];
                let contents = [];
                let currentHeading = {name: '', content: [], style: null, level: 0, index: 0, parentIndex: null};
                const pushCurrentHeading = () => {
                    if (currentHeading.style && currentHeading.name.trim() !== '') {
                        steps.push({
                            name: currentHeading.name.trim(),
                            level: currentHeading.level,
                            index: currentHeading.index,
                            parentIndex: currentHeading.parentIndex,
                        });
                        contents.push({
                            content: currentHeading.content,
                            level: currentHeading.level,
                            index: currentHeading.index,
                            parentIndex: currentHeading.parentIndex,
                        });
                    }
                };
                let lastH1Index = -1;
                let lastH2Index = -1;
                data?.content.forEach((item, index) => {
                    if (item.paragraph) {
                        const {namedStyleType} = item.paragraph.paragraphStyle;
                        if (namedStyleType === 'HEADING_1' || ((labConfig.slideHeading === 'h2' || labConfig.slideHeading === 'h3') ? namedStyleType === 'HEADING_2' : false) || (labConfig.slideHeading === 'h3' ? namedStyleType === 'HEADING_3' : false)) {
                            if (labConfig.slideHeading === 'h1' && namedStyleType === 'HEADING_1') {
                                pushCurrentHeading();
                            } else if (labConfig.slideHeading === 'h2' && (namedStyleType === 'HEADING_1' || namedStyleType === 'HEADING_2')) {
                                pushCurrentHeading();
                            } else if (labConfig.slideHeading === 'h3' && (namedStyleType === 'HEADING_1' || namedStyleType === 'HEADING_2' || namedStyleType === 'HEADING_3')) {
                                pushCurrentHeading();
                            }
                            currentHeading = {
                                name: item.paragraph.elements[0]?.textRun?.content || '',
                                content: [],
                                style: item.paragraph.paragraphStyle,
                                level: namedStyleType === 'HEADING_1' ? 1 : namedStyleType === 'HEADING_2' ? 2 : 3,
                                index: index,
                                parentIndex: namedStyleType === 'HEADING_1' ? -1 : (namedStyleType === 'HEADING_2' ? lastH1Index : lastH2Index)
                            };
                            if (namedStyleType === 'HEADING_1') {
                                lastH1Index = index;
                            } else if (namedStyleType === 'HEADING_2') {
                                lastH2Index = index;
                            }
                        } else {
                            currentHeading.content.push(item);
                        }
                        if (namedStyleType === 'TITLE') {
                            pushCurrentHeading();
                            if (labConfig.slideHeading === "h1") {
                                setSlideTitle(item.paragraph.elements[0]?.textRun?.content || '')
                            }
                            currentHeading = {
                                name: item.paragraph.elements[0]?.textRun?.content || '',
                                content: [],
                                style: item.paragraph.paragraphStyle,
                                level: 0, // Đặt level là 0 cho 'TITLE'
                                index: index,
                                parentIndex: null,
                            };
                        }
                    } else if (item) {
                        currentHeading.content.push(item);
                    }
                });
                pushCurrentHeading();
                setStepsData({steps, contents, listHeading});
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        if (isRoomInPath) {
            if (!user) setLoading(!user); else fetchData()
        } else {
            fetchData();
        }
    }, [id, user, selectedItem, chap, prevChap]);

    function afterPreview() {
        // var element = document.getElementById('setting');
        // var parent = element.parentNode;
        //
        // const button = document.getElementById('settings-button');
        // button.onclick = function () {
        //     if (settingsChangeCounter) setSettingsChangeCounter(false); else setSettingsChangeCounter(true);
        // };
        // let elementCopy = element.cloneNode(true);
        // parent.removeChild(element);
        // document.body.appendChild(elementCopy);
    }

    function createToc(config) {
        const content = config.content;
        const tocElement = config.tocElement;
        const titleElements = config.titleElements;
        let tocElementDiv = content.querySelector(tocElement);
        let tocUl = document.createElement("ul");
        tocUl.id = "list-toc-generated";
        tocElementDiv.appendChild(tocUl);
        // add class to all title elements
        let tocElementNbr = 0;
        for (var i = 0; i < titleElements.length; i++) {
            let titleHierarchy = i + 1;
            let titleElement = content.querySelectorAll(titleElements[i]);
            titleElement.forEach(function (element) {
                // add classes to the element
                element.classList.add("title-element");
                element.setAttribute("data-title-level", titleHierarchy);
                // add id if doesn't exist
                tocElementNbr++;
                let idElement = element.id;
                if (idElement == '') {
                    element.id = 'title-element-' + tocElementNbr;
                }
                let newIdElement = element.id;
            });
        }

        // create toc list
        let tocElements = content.querySelectorAll(".title-element");

        for (var i = 0; i < tocElements.length; i++) {
            let tocElement = tocElements[i];

            let tocNewLi = document.createElement("li");

            // Add class for the hierarcy of toc
            tocNewLi.classList.add("toc-element");
            //tocNewLi.classList.add("toc-element-level-" + tocElement.dataset.titleLevel);

            // Keep class of title elements
            let classTocElement = tocElement.classList;
            for (var n = 0; n < classTocElement.length; n++) {
                if (classTocElement[n] != "title-element") {
                    tocNewLi.classList.add(classTocElement[n]);
                }
            }

            // Create the element
            tocNewLi.innerHTML = '<a href="#' + tocElement.id + '">' + tocElement.innerHTML + '</a>';
            tocUl.appendChild(tocNewLi);
        }

    }


    useEffect(() => {
        if (!loading && print==="paged") {
            let paged = new Previewer();
            let DOMContent = document.querySelector('main-content');
            // class TOCHandler extends Handler {
            //     beforeParsed(content) {
            //         createToc({
            //             content: content, tocElement: '#my-toc-content', titleElements: ['h1', 'h2'],
            //         });
            //     }
            // }
            //
            // registerHandlers(TOCHandler);
            paged.preview(DOMContent, ["../css/print.css"], document.body).then((flow) => {
                console.log('Rendered', flow.total, 'pages.');
                afterPreview();
            });
        }
    }, [loading, settingsChangeCounter]);

    useEffect(() => {
        const hashIndex = parseInt(window.location.hash.substring(1), 10);
        if (!isNaN(hashIndex) && hashIndex > 0 && hashIndex <= stepsData.steps.length) {
            setCurrentStep(hashIndex);
        }
    }, [stepsData]);
    return (<>
        <div className={`preview-container ${template} ${location.search.includes('print') ? 'print' : ''}`}>
            <Header
                stepsData={stepsData}
                currentStep={currentStep}
                handleStepClick={handleStepClick}
                showStepList={showStepList}
                onOptionClick={handleOptionClick}
                slideTitle={slideTitle}
                room={room}
                chap={chap}
                onUserLogin={handleUserLogin}
                loading={loading}
                onTitleClick={handleTitleClick}
            />
            {/*<div id="setting">*/}
            {/*    <SettingsButton/>*/}
            {/*</div>*/}

            {/*<div id="table-of-content">*/}
            {/*    Table of Content*/}
            {/*    <span id="my-toc-content">*/}
            {/*    </span>*/}
            {/*</div>*/}
            {loading ? (<div className="main-center">
                <div className="spinner-border text-primary " role="status"></div>
            </div>) : (room && (<div
                className={`d-flex justify-content-center ${stepsData.steps.length === 1 ? 'content-full' : 'content'}`}>
                <div className="main-content">
                    {print? (stepsData.contents.map((content, index) => (<StepContent
                        key={index}
                        room={room}
                        steps={stepsData.steps}
                        step={stepsData.steps[index]}
                        content={content}
                        currentStep={index}
                    />))) : (<StepContent room={room} steps={stepsData.steps} step={stepsData.steps[currentStep]}
                                          content={stepsData.contents[currentStep]} currentStep={currentStep}/>)}
                </div>
            </div>))}
        </div>
    </>);
};

export default Preview;
