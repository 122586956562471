import React, {useState} from 'react';
import config from "../config";

const UploadFilePage = ({uploadDone, user}) => {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        uploadFile(selectedFile);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        setFile(droppedFile);
        uploadFile(droppedFile);
    };

    const handleButtonClick = () => {
        document.getElementById('fileInput').click();
    };

    const uploadFile = async (file) => {
        setUploading(true);

        try {
            let formData = new FormData();
            formData.append("files", file);
            if (user) formData.append("email", user.email);
            const response = await fetch(`${config.API_BASE_URL}/uploadMultipleFiles`, {
                method: 'POST', body: formData
            });

            if (response.ok) {
                const responseData = await response.text();
                console.log("Server response:", responseData);
                // window.location.href = "/preview/" + responseData;
                if (user) window.open("/preview/" + user.email + "/" + responseData, '_blank'); else window.open("/preview/" + responseData, '_blank');
                uploadDone(); // Close the modal
            } else {
                console.error("Request failed with status:", response.status, response.statusText);
            }
        } catch (error) {
            console.error("Request failed:", error.message);
        } finally {
            setUploading(false);
        }
    };

    return (<div className="container-fluid drag-area-parent">
        <div
            className={`drag-area ${uploading ? 'uploading' : ''}`}
            onDragOver={(event) => event.preventDefault()}
            onDrop={handleDrop}
        >
            <h4 className="text-center mb-4">{uploading ? 'Vui lòng đợi' : 'Kéo thả file docx vào đây'}</h4>
            <p className="text-center">hoặc</p>
            <button className="btn btn-primary btn-block" onClick={handleButtonClick} disabled={uploading}>
                Chọn file
            </button>
            <input
                id="fileInput"
                type="file"
                hidden
                onChange={handleFileChange}
                disabled={uploading}
            />
        </div>
    </div>);
};

export default UploadFilePage;
