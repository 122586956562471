import Home from "./containers/Home";
import MyLabs from "./containers/MyLabs";
import MyClasses from "./containers/MyClasses";
import Room from "./containers/Room";
import Test from "./containers/Test";
import Preview from "./containers/Preview";
import UploadFilePage from "./containers/UploadFilePage";
import PrintDocument from './containers/PrintDocument';

import {
    BrowserRouter, Routes, Route,
} from "react-router-dom";

function App() {
    return (<BrowserRouter basename="/">
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="docs" element={<Home/>}/>
            <Route path="MyClasses" element={<MyClasses/>}/>
            <Route path="test" element={<Test/>}/>
            <Route path="unit/:year/:id" element={<Preview/>}/>
            <Route path="preview" element={<UploadFilePage/>}/>
            <Route path="preview/:email/:id" element={<Preview />}/>
            <Route path="preview/:id" element={<Preview />}/>
            <Route path="room/:id" element={<Preview/>}/>
            <Route path="print" element={<PrintDocument/>}/>
        </Routes>
    </BrowserRouter>);
}

export default App;



