import React, {useState} from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {docco, darcula, irBlack} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {RxCopy} from 'react-icons/rx';
import {IoMdCheckmark} from 'react-icons/io';
import {FiSun, FiMoon} from 'react-icons/fi';
import { MdOutlineWrapText } from "react-icons/md";

const CodeDisplay = ({table}) => {
    const [copied, setCopied] = useState(false);
    const [theme, setTheme] = useState('darcula');
    const [wrapLines, setWrapLines] = useState(true);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
        navigator.clipboard.writeText(codeString)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 1500);
            })
            .catch((err) => {
                console.error('Unable to copy to clipboard', err);
            });
    };
    const toggleWrapLines = () => {
        setWrapLines((prevWrapLines) => !prevWrapLines);
    };
    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'docco' ? 'irBlack' : 'docco'));
    };

    const cellContent = table.tableRows[0].tableCells[0].content;
    const extractTextContent = (cell) => {
        return cell.content
            .map((paragraph) => paragraph.paragraph.elements.map((element) => element.textRun.content).join(''))
            .join('')
            .trim();
    };

    const extractRowContent = (row) => {
        return row.tableCells.map((cell) => extractTextContent(cell)).join('').trim();
    };

    const extractTableContent = (table) => {
        return table.tableRows.map((row) => extractRowContent(row)).join('').trim();
    };

    const codeString = extractTableContent(table);
    const numberOfLines = codeString.split('\n').length;

    return (<div className="code-container">
        <div className="code-tool">
            <div className="left-elements">
            </div>
            <div className="right-elements">
                <span className="copy-button" onClick={() => {
                    handleCopy();
                }}>
                    {copied ? <IoMdCheckmark/> : <RxCopy/>} {copied ? `Copied` : `Copy`}
                </span>
                <span className="switch-button" onClick={toggleTheme}>
                    {theme === 'irBlack' ? <FiSun/> : <FiMoon/>}
                </span>
                <span className="wrapline-button" onClick={toggleWrapLines}>
                <MdOutlineWrapText/>
  </span>
            </div>
        </div>
        <SyntaxHighlighter
            language="auto"
            style={theme === 'irBlack' ? irBlack : docco}
            showLineNumbers={numberOfLines > 1}
            wrapLines={wrapLines}
            lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
        >
            {codeString}
        </SyntaxHighlighter>

    </div>);
};

export default CodeDisplay;
