// StepHeader.js
import React from 'react';
import {useLocation} from "react-router-dom";

const StepHeader = ({step, labConfig}) => {
    const print = new URLSearchParams(useLocation().search).get('print');
    const HeadingTag = print ? 'h'+step.level : 'h1';

    return <div>
        <div className="colored-line">
            <div className="part part1"></div>
            <div className="part part2"></div>
            <div className="part part3"></div>
        </div>
        <HeadingTag className="step-title">{step.name}</HeadingTag>
    </div>;
};

export default StepHeader;
