// Header.js
import React, {useEffect, useState} from 'react';
import StepList from "./StepList";
import app from "../firebase";
import firebase from "firebase/compat/app";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import LoginModal from "../modals/LoginModal";
import AddRoomModal from "../modals/AddRoomModal";
import QRModal from "../modals/QRModal";
import UserMenu from "../components/UserMenu";
import {Button, Dropdown} from 'react-bootstrap';
import {getDatabase, set, ref} from "firebase/database";
import {MdQrCode} from "react-icons/md";
import {GrPrint} from "react-icons/gr";

const Header = ({
                    stepsData,
                    currentStep,
                    handleStepClick,
                    showStepList,
                    onOptionClick,
                    onTitleClick,
                    room,
                    onUserLogin,
                    loading,
                    chap,
                    slideTitle
                }) => {
    const [user, setUser] = useState(null);
    const [teacher, setTeacher] = useState(null);
    const [showQRModal, setShowQRModal] = useState(false);
    const isRoomInPath = window.location.pathname.includes('room');

    const handleQrCodeClick = () => {
        setShowQRModal(true);
    };
    const handlePrintClick = () => {
        const url = new URL(window.location.href);
        url.searchParams.set('print', 'true');
        window.location.href = url.toString();
    };
    const [loadingLogin, setLoadingLogin] = useState(true);
    const [showLogin, setShowLogin] = useState(false);

    const [showAddRoom, setShowAddRoom] = useState(false);
    const handleShowLogin = () => {
        setShowLogin(true);
    }
    const handleCloseLogin = () => {
        setShowLogin(false);
    }
    const handleCloseAddRoom = () => {
        setShowAddRoom(false);
    }
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const handleOptionSelect = (selectedItem, selectedIndex) => {
        setSelectedOption(selectedItem);
        setSelectedIndex(selectedIndex);
        onOptionClick(selectedIndex + 1)
    };

    const handleTitleClick = () => {
        if (room.config.slideHeading === "h1") onTitleClick()
    };
    const handlerLogout = () => {
        if (room.roomID) {
            const db = getDatabase(app);
            let link = chap ? `/chap${chap}` : "";
            const refUsers = ref(db, `/labs/${room.docID.replace(/\./g, '')}/${room.roomID}${link}/users/${user.uid}`)
            set(refUsers, {})
        }
        firebase.auth().signOut()
    };

    const loadRooms = () => {
        setShowAddRoom(true)
    }

    useEffect(() => {
        const auth = getAuth(app);
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                onUserLogin(currentUser);
            } else {
                setUser(null);
                onUserLogin(null);
            }
            setLoadingLogin(false);
        });
        if (chap && stepsData?.listHeading) {
            setSelectedOption(stepsData.listHeading[chap - 1]);
            setSelectedIndex(chap - 1);
        }
        if (isRoomInPath && !user) {
            setShowLogin(true);
        }
    }, [onUserLogin, stepsData, chap, user, room]);

    return (<div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top ps-3">
            <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"/>
            </button>
            {(stepsData?.listHeading?.length > 0 || slideTitle) && (<div
                className="nav-center d-flex hide-expand">
                {(<div className="chapter-select-wrap">
                    <a
                        className={`nav-link  fw-bold chapter-select ${slideTitle ? '' : 'dropdown-toggle'}`}
                        href="#" role="button"
                        onClick={handleTitleClick}
                        data-bs-toggle={`${slideTitle ? '' : 'dropdown'}`} aria-expanded="false">
                        {selectedOption?.toUpperCase() || slideTitle || "NỘI DUNG"}
                    </a>
                    {!slideTitle && (<ul className="dropdown-menu chapter-option">
                        {stepsData?.listHeading?.map((item, index) => (<li key={index}> {/* Thêm key cho mỗi item */}
                            <a className={`dropdown-item ${selectedIndex === index ? 'highlighted' : ''}`}
                               href="#"
                               onClick={() => {
                                   handleOptionSelect(item, index);
                               }}
                            >{item}</a>
                        </li>))}
                    </ul>)}
                </div>)}
            </div>)}
            <div className="print-button hide-expand">

            </div>
            <div className="print-button hide-expand">
                <GrPrint className="me-2" size={24} onClick={() => handlePrintClick()}/>
            </div>

            {(isRoomInPath) && (<div className="qrcode-button hide-expand">
                <MdQrCode className=" me-2" size={24} onClick={() => handleQrCodeClick()}/>
            </div>)}

            {(user && !isRoomInPath) && (<div className="hide-expand btn-group teacher me-3 room">
                <button type="button" className="btn btn-success dropdown-toggle dropdown-toggle"
                        data-bs-toggle="dropdown" aria-expanded="false">
                    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.00024 11.9994C2.00024 16.7135 2.00024 19.0705 3.46471 20.535C4.92918 21.9994 7.2862 21.9994 12.0002 21.9994C16.7143 21.9994 19.0713 21.9994 20.5358 20.535C21.8928 19.1779 21.9924 17.0545 21.9997 12.9994M11.0002 2C6.94518 2.0073 4.82174 2.10686 3.46471 3.46389C2.64814 4.28046 2.28688 5.37454 2.12705 7"
                            stroke="#fff" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M13 11L22 2M22 2H16.6562M22 2V7.34375M21 3L12 12M12 12H16M12 12V8" stroke="#fff"
                              stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span className="ms-2 d-none d-lg-inline">Phòng học</span>
                </button>
                <ul className="dropdown-menu">
                    <li><a className="dropdown-item preview" href="#" onClick={loadRooms}>Danh sách phòng học</a>
                    </li>
                </ul>
            </div>)}

            <div className="login me-4 hide-expand">
                {loadingLogin ? (<div id="login-spinner" className="spinner-border text-light ms-3"
                                      role="status"/>) : user ? (<UserMenu user={user} onLogout={handlerLogout}/>) : (
                    <Button variant="primary" onClick={handleShowLogin}>
                        Đăng nhập
                    </Button>)}
            </div>
            <LoginModal
                show={!user && showLogin}
                handleClose={handleCloseLogin}
                user={user}
                onSignOut={() => {
                    firebase.auth().signOut();
                }}
            />
            <QRModal room={room} show={showQRModal} handleClose={() => setShowQRModal(false)}/>
            {user && showAddRoom && room && (<AddRoomModal
                show={user && showAddRoom}
                user={user}
                handleClose={handleCloseAddRoom}
                room={room}
            />)}

            <div className="collapse navbar-collapse" id="navbarNav">
                <div className="sidebar d-flex justify-content-center">
                    {!loading && (<StepList steps={stepsData.steps} currentStep={currentStep}
                                            handleStepClick={handleStepClick}
                                            show={showStepList}
                                            room={room}
                                            user={user}
                    />)}
                </div>
            </div>
        </nav>
    </div>)
};

export default Header;
