import React, {useState, useEffect} from 'react';
import StepButton from './StepButton';
import app from "../firebase.js";
import {getDatabase, ref, onValue} from "firebase/database";
import {useLocation} from "react-router-dom";


const StepList = ({steps, currentStep, handleStepClick, isVisible, room}) => {
    let heading1Count = 0;
    let heading2Count = 0;
    let heading3Count = 0;
    const [onlineList, setOnlineList] = useState([]);
    const db = getDatabase(app);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const chap = searchParams.get('chap');

    useEffect(() => {
        const handleKeyDown = (e) => {
            if ((e.key === 'ArrowLeft' || e.key === 'PageUp'||e.key === 'VolumeUp') && currentStep > 0) {
                handleStepClick(currentStep - 1);
                e.preventDefault();
            } else if ((e.key === 'ArrowRight' || e.key === 'PageDown' ||e.key === 'VolumeDown' || e.key === 'Space') && currentStep < steps.length - 1) {
                handleStepClick(currentStep + 1);
                e.preventDefault();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentStep, handleStepClick, steps]);

    useEffect(() => {
        if (room.roomID) {
            let link = chap ? `/chap${chap}` : "";
            onValue(ref(db, `/labs/${room.docID.replace(/\./g, '')}/${room.roomID}${link}/users`), (snapshot) => {
                let data = snapshot.val();
                let count = []
                let totalUser = 0;
                for (let uid in data) {
                    let step = data[uid].step;
                    if (count[step] == undefined) count[step] = {count: 0, user: ""};
                    count[step].count++;
                    count[step].user = count[step].user + data[uid].name + "<br>";
                    totalUser++;
                    setOnlineList(count)
                }
            });
        }
    }, [room]);

    return (<ul className={`me-auto list-group ${isVisible ? 'visible' : 'hidden'}`}>
        {steps.map((step, index) => {
            if (room.config.slideHeading === "h1") {
                if (step.level === 1) {
                    heading1Count++
                } else {
                    return;
                }
            } else {
                if (step.level === 1 || step.level === 0) {
                    // heading2Count = 0;
                    // heading3Count = 0;
                    // heading1Count++;
                    return;
                } else if (step.level === 2) {
                    heading3Count = 0; // Reset heading3Count when encountering heading level 2
                    heading2Count++;
                } else if (step.level === 3) {
                    heading3Count++;
                }
            }
            return (<StepButton
                key={index}
                step={step}
                index={index}
                currentStep={currentStep}
                handleStepClick={handleStepClick}
                heading1Count={heading1Count}
                heading2Count={heading2Count}
                heading3Count={heading3Count}
                onlineList={onlineList}
                room={room}
            >
                {step.name}
            </StepButton>);
        })}
    </ul>);
};

export default StepList;
