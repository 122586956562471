// StepBody.js
import React from 'react';
import ParagraphContent from './ParagraphContent';

const StepBody = ({content, step}) => {
    const groupedParagraphs = groupParagraphsByList(content);

    return (<div>
        {groupedParagraphs.map((group, index) => (<React.Fragment key={index}>
            {group.listId ? (<ul>
                {group.paragraphs.map((paragraph, idx) => (<li key={idx}>
                    <ParagraphContent content={paragraph}/>
                </li>))}
            </ul>) : (group.paragraphs.map((paragraph, idx) => (<ParagraphContent key={idx} content={paragraph}/>)))}
        </React.Fragment>))}
    </div>);
};

const groupParagraphsByList = (paragraphs) => {
    if (!paragraphs || !Array.isArray(paragraphs)) {
        return [];
    }
    const grouped = [];
    let currentGroup = {listId: null, paragraphs: []};
    paragraphs.forEach((paragraph) => {
        if (paragraph && paragraph.paragraph && paragraph.paragraph.bullet) {
            const listId = paragraph.paragraph.bullet.listId;
            if (currentGroup.listId === null || currentGroup.listId === listId) {
                // Add to the current group
                grouped.push({...currentGroup});
                currentGroup = {listId, paragraphs: [paragraph]};
            } else {
                // Start a new group
                currentGroup.listId = listId;
                currentGroup = {listId, paragraphs: [paragraph]};
            }
        } else {
            // No bullet or invalid paragraph, treat as a new group
            grouped.push({...currentGroup});
            currentGroup = {listId: null, paragraphs: [paragraph]};
        }
    });

    if (currentGroup.paragraphs.length > 0) {
        grouped.push({...currentGroup});
    }

    return grouped;
};

export default StepBody;
